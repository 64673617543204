.rootClassName{
  left: 0 !important;
  max-width: 100%;
  overflow: hidden;
}

.containerDropdown{
  padding: 24px 12px;
  background-color: white;
  box-shadow: var(--box-shadow-full-dropdown);
  width: 100vw;
}

.sectionTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--grey-9);
  line-height: 28px;
  margin: 0;
  margin-bottom: 12px;
  margin-left: 14px;
}

.postsSkeleton{
  width: 100%;
  overflow: hidden;
}

.postVisit{
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-10px);

  :global .anticon{
    color: var(--primary-color);
  }
}

.postItem{
  display: flex;
  align-items: center;
  padding: 12px;
  transition: all 0.3s;
  border-radius: 4px;

  &:hover{
    background-color: var(--grey-2);
    transition: all 0.3s;

    .postVisit{
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.postImage{
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 16px;

  img{
    border-radius: 4px;
  }
}

.postContent{
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 10px;

  :global {

    h4.ant-typography, div.ant-typography-h4, div.ant-typography-h4> textarea, .ant-typography h4{
      font-size: 16px;
      color: var(--grey-9);
      font-weight: 600;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 8px;
    }

    div.ant-typography, .ant-typography p{
      margin-bottom: 0;
    }
  }
}

.excerpt{
  font-size: 14px;
  font-weight: 400;
  color: var(--grey-8);
  line-height: 22px;
}

.viewAllLink{
  display: inline-block;
  margin-top: 10px;
  margin-left: 14px;
  color: var(--primary-color);

  :global .anticon{
    transform: translateX(0);
    transition: all 0.3s;
  }

  
  &:hover{
    color: var(--primary-color);
    transition: all 0.3s;

    :global .anticon{
      transition: all 0.3s;
      transform: translateX(5px);
    }
  }

}